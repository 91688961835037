export const LOADING_SESSION = "LOADING_SESSION";
export const FETCH_SESSION = "FETCH_SESSION";
export const SET_SESSION = "SET_SESSION";


export const loadingSession = (session) => ({
  type: LOADING_SESSION,
  payload: {},
});

export const setSession = (session) => ({
  type: SET_SESSION,
  payload: session,
});

export const fetchSession = (payload) => ({
  type: FETCH_SESSION,
  payload,
});
