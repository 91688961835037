import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchSession } from "store/session/actions";
import { fetchClientBasicData } from "store/clientBasicData/actions.js";
import _ from "lodash";


const PublicRoute = ({ children, ...props }) => {
  const dispatch = useDispatch();
  const subdomain = _.get(props, "computedMatch.params.portalSlug");

  console.log("SUBDOMAIN IN PUBLIC ROUTE", subdomain, props)

  useEffect(() => {
    dispatch(fetchClientBasicData(subdomain));
  }, [dispatch, subdomain]);

  useEffect(() => {
    console.log("FETCHING in public, ", subdomain)
    dispatch(fetchSession(subdomain));
  }, [dispatch, subdomain]);

  return <Route {...props}>{children}</Route>;
};

export default PublicRoute;
