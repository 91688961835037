import { put, call, takeLatest } from "redux-saga/effects";
import { FETCH_CLIENT_BASIC_DATA, setClientBasicData } from "./actions";
import { fetchClientBasicData } from "./request";

export function* fetchClientBasicDataMiddleware({ payload: subdomain}) {
  try {
    const response = yield call(fetchClientBasicData, subdomain);
    const {
      data: { portal = {} },
      error,
    } = response.data;
    if (!error) {
      yield put(setClientBasicData(portal));
    } else if (error) {
      yield put(setClientBasicData({ error }));
    }
  } catch (err) {
    yield put(setClientBasicData({ error: err.response.data }));
  }
}

export default function* clientBasicDataRequestWatcher() {
  yield takeLatest(FETCH_CLIENT_BASIC_DATA, fetchClientBasicDataMiddleware);
}
