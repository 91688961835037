import { LOADING_CLIENT_BASIC_DATA, SET_CLIENT_BASIC_DATA } from "./actions";

const initialState = {
  loading: true,
  portal: {},
};

const clientBasicData = (state = initialState, { payload, type }) => {
  switch (type) {
    case LOADING_CLIENT_BASIC_DATA:
      return {
        loading: true,
        portal: {},
      };
    case SET_CLIENT_BASIC_DATA:
      return {
        loading: false,
        portal: payload,
      };
    default:
      return state;
  }
};

export default clientBasicData;
