import React from 'react';
import styles from './styles.module.less';
import loading from 'static/loading.svg';
// import logo from 'static/logo-square.png';

const Fallback = () => {

  return (
    <div className={styles.fallback}>
      {/* <img src="https://res.cloudinary.com/ditoriefv/image/upload/v1652644977/klubmeet/rentobuzz_lotz1o.png" alt="logo" width="170" /> */}
      <img src={loading} className={styles.loading} alt="loading" width="100"/>
    </div>
  )
}

export default Fallback;