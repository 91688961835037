import React, { useEffect } from "react";
import _ from "lodash";
import { Route } from "react-router-dom";
import { Menu, Layout } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchSession, setSession } from "store/session/actions";
import Fallback from "./Fallback";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { getThemeBackgroundURL } from "utils/utils";
import { logout } from "utils/auth";
import { fetchClientBasicData } from "store/clientBasicData/actions.js";
import { getUserLogoutRequest } from "../components/LayoutWrapper/requests"

import styles from "./styles.module.less";

const { Header } = Layout;

const PrivateRoute = ({ children, ...props }) => {
  const subdomain = _.get(props, "computedMatch.params.portalSlug");
  const dispatch = useDispatch();
  const history = useHistory();
  const session = useSelector((globalState) => globalState.session);
  const clientBasicData = useSelector((globalState) => globalState.clientBasicData);
  const isLoggedIn = !!_.get(session, "user.customer._id");

  useEffect(() => {
    dispatch(fetchSession(subdomain));
  }, [dispatch, subdomain]);

  useEffect(() => {
    dispatch(fetchClientBasicData(subdomain));
  }, [dispatch, subdomain]);

  if (session.loading || clientBasicData.loading) {
    return <Fallback active />;
  }

  console.log("Fetching in private: ", isLoggedIn);
  if (!isLoggedIn) {
    history.push(`/${subdomain}/auth/login`);
  }

  const firstName = _.get(session, "user.customer.firstName", "FrontBase")
  const lastName = _.get(session, "user.customer.lastName", "Customer")

  const backgroundImageId = _.get(clientBasicData,"portal.theme.background");

  const handleLogout = async () => {
    await getUserLogoutRequest();
    logout();
    dispatch(setSession({}));
    history.push(`/${subdomain}/auth/login`);
  }

  return (
    <Route {...props}>
      <div className={styles.homeScreenContainer}>
        <Header className={styles.headerSection}>
          <div className={styles.logo} onClick={() => history.push(`/${subdomain}/home`)}>
            <img src={clientBasicData.portal.logo} alt="logo"/>
          </div>
          <Menu theme="dark" mode="horizontal">
            <Menu.SubMenu key="SubMenu" title={`${firstName} ${lastName}`} icon={<UserOutlined />}>
              <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
                Logout
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
        </Header>
        {props.showBackgroundImage && <div className={styles.backgroundImage} style={{ backgroundImage: `url("${getThemeBackgroundURL(backgroundImageId)}")` }} /> }
        {React.cloneElement(children, props)}
      </div>
    </Route>
  );
};

export default PrivateRoute;
