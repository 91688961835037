import { useParams } from "react-router-dom/cjs/react-router-dom.min";
// import { isProduction, LOCAL_SUBDOMAIN } from "constants/configs";

export const useSubDomain = () => {
  const { portalSlug } = useParams();
  // if (!isProduction) return LOCAL_SUBDOMAIN;
  // return window.location.host.split(".")[0];
  return portalSlug;
};
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const getThemeBackgroundURL = (backgroundId) => {
    switch(backgroundId) {
      case "1": return "https://res.cloudinary.com/ditoriefv/image/upload/v1724358054/production/themes/theme-7_oe0kkr.svg"
      case "2": return "https://res.cloudinary.com/ditoriefv/image/upload/v1724358054/production/themes/theme-8_ubuj7u.svg"
      case "3": return "https://res.cloudinary.com/ditoriefv/image/upload/v1724358053/production/themes/theme-5_ieofi8.svg"
      case "4": return "https://res.cloudinary.com/ditoriefv/image/upload/v1724358052/production/themes/theme-2_msa5mb.svg"
      case "5": return "https://res.cloudinary.com/ditoriefv/image/upload/v1725763611/production/themes/theme-1.svg"
      default: return "https://res.cloudinary.com/ditoriefv/image/upload/v1724358052/production/themes/theme-2_msa5mb.svg"
    }
}
