import Routes from "routes";
import ReduxWrapper from "./store/index";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <ReduxWrapper>
      <Router>
        <Routes />
      </Router>
    </ReduxWrapper>
  );
}

export default App;
