import { combineReducers } from "redux";
import session from "./session/reducer";
import clientBasicData from "./clientBasicData/reducer";

const rootReducer = combineReducers({
  session,
  clientBasicData,
});

export default rootReducer;
