import React from "react";
import { Switch, Redirect } from "react-router-dom";
import _ from "lodash";
import routes from "./routes.jsx";
import PrivateRoute from "./PrivateRoute.jsx";
import PublicRoute from "./PublicRoute.jsx";
import Fallback from "./Fallback";
import { useSelector } from "react-redux";
const isApp = window.location.host === "go.frontbaseapp.com";
const isHome =
  window.location.host === "www.frontbaseapp.com" ||
  window.location.host === "home.frontbaseapp.com" ||
  window.location.host === "frontbaseapp.com";

const App = () => {
  const session = useSelector((globalState) => globalState.session);
  const isLoggedIn = _.get(session, "user.customer._id");
  const sessionLoading = _.get(session, "loading", true);

  console.log("isLoggedIn", isLoggedIn )
  console.log("sessionLoading", sessionLoading )

  if (isHome) {
    window.location.href = "https://home.frontbaseapp.com";
    return <Fallback />;
  }
  if (isApp) {
    window.location.href = "https://app.frontbaseapp.com";
    return <Fallback />;
  }

  return (
      <Switch>
        {_.map(routes, (route) => {
          const Component = route.component;
          const allowedRoles = _.get(route, "roles", []);
          const RouteForPath =
            allowedRoles.length > 0 ? PrivateRoute : PublicRoute;
          return (
            <RouteForPath
              key={route.path}
              path={route.path}
              exact={route.exact}
              showLeftNavigation={route.showLeftNavigation}
              showBackgroundImage={route.showBackgroundImage}
            >
              <React.Suspense
                fallback={() => {
                  <Fallback />;
                }}
              >
                <Component path={route.path} />
              </React.Suspense>
            </RouteForPath>
          );
        })}
        <PublicRoute path="/" exact>
          {!sessionLoading && isLoggedIn && <Redirect to="/:portalSlug/home" /> }
          {!sessionLoading && !isLoggedIn && <Redirect to="/:portalSlug/auth/login" /> }
          {sessionLoading && <Fallback />}
        </PublicRoute>
        <PublicRoute path="/:portalSlug" exact>
          {!sessionLoading && isLoggedIn &&  <Redirect to={`${window.location.pathname}/home`} /> }
          {!sessionLoading && !isLoggedIn &&  <Redirect to={`${window.location.pathname}/auth/login`} /> }
          {sessionLoading && <Fallback />}
        </PublicRoute>
      </Switch>
  );
};

export default App;
