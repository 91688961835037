export const LOADING_CLIENT_BASIC_DATA = "LOADING_CLIENT_BASIC_DATA";
export const FETCH_CLIENT_BASIC_DATA = "FETCH_CLIENT_BASIC_DATA";
export const SET_CLIENT_BASIC_DATA = "SET_CLIENT_BASIC_DATA";

export const setClientBasicData = (clientBasicData) => ({
  type: SET_CLIENT_BASIC_DATA,
  payload: clientBasicData,
});

export const fetchClientBasicData = (subdomain) => ({
  type: FETCH_CLIENT_BASIC_DATA,
  payload: subdomain,
});
