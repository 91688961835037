// import { authUrls } from "constants/routeUrls";
// import { Modal } from "antd";

export const authHeaders = (headers) => {
  const token = localStorage.getItem("token");
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
};

// export const authError = () => {
//   const showUnauthorized = localStorage.getItem("showUnauthorized");
//   if (!showUnauthorized) {
//     localStorage.setItem("userRoute", "/home");
//     localStorage.setItem("showUnauthorized", JSON.stringify(true));
//     Modal.warning({
//       title: "Session logged out",
//       content: "Please login to continue",
//       okText: "Login",
//       onOk: () => {
//         setTimeout(() => {
//           window.location.href = authUrls.login();
//           localStorage.setItem("showUnauthorized", JSON.stringify(false));
//         }, 200);
//       },
//     });
//   }
// };

export const logout = () => {
  localStorage.clear();
};
